//Login
export const LOGIN_USER = 'LOGIN_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCEPT_INVITE_FAILED = 'ACCEPT_INVITE_FAILED';
export const LOGIN_ERROR = 'LOGIN_ERROR';


//Comapnies
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_PLANS = 'GET_PLANS';
export const GET_ENTERPRISE_PLANS = 'GET_ENTERPRISE_PLANS';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_MANAGER = 'GET_MANAGER';
export const TOGGLE_STATUS = 'TOGGLE_STATUS';
export const REDIRECT_LOGIN = 'REDIRECT_LOGIN';

//rewards
export const GET_REWARDS_LIST = 'GET_REWARDS_LIST';
export const GET_NEXT_REWARDS_LIST = 'GET_NEXT_REWARDS_LIST';
export const REFRESH_LIST = 'REFRESH_LIST';
export const VOUCHER_SENT = 'VOUCHER_SENT';
export const GET_REWARDS = 'GET_REWARDS';
export const REWARD_STATUS = 'REWARD_STATUS';
export const Select_One = 'Select_One';
export const GET_CHECKED = 'GET_CHECKED';
export const GET_CAMPUS_REWARDS = 'GET_CAMPUS_REWARDS';
export const GET_NEXT_CAMPUS_REWARDS = 'GET_NEXT_CAMPUS_REWARDS';
//Provider
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const PROVIDER_STATUS = 'PROVIDER_STATUS';

//mentor sessions
export const GET_SESSIONS = 'GET_SESSIONS';
export const GET_NEXT_SESSIONS = 'GET_NEXT_SESSIONS';
//user-content
export const GET_CONTENT_LIST = 'GET_CONTENT_LIST';
export const GET_NEXT_CONTENT_LIST = 'GET_NEXT_CONTENT_LIST';
export const REFRESH_CONTENT = 'REFRESH_CONTENT';
export const CHANGE_CONTENT_STATUS = 'CHANGE_CONTENT_STATUS';
export const APPROVED = 'APPROVED';


//updateContent
export const SEARCHED_CONTENT = 'SEARCHED_CONTENT';


// test 
export const GET_TEST_LIST = 'GET_TEST_LIST';
export const GET_NEXT_TEST_LIST = 'GET_NEXT_TEST_LIST';
export const GET_V2_TEST_LIST = 'GET_V2_TEST_LIST';
export const GET_NEXT_V2_TEST_LIST = 'GET_NEXT_V2_TEST_LIST';
export const GET_REPORT = 'GET_REPORT';
export const REFRESH_TEST = 'REFRESH_TEST';
export const SCHEDULE_TEST = 'SCHEDULE_TEST';
export const SUBMIT_RESULT = 'SUBMIT_RESULT';
export const SEARCH_TEST = 'SEARCH_TEST';

//job title
export const COMPANY_SUGGESTIONS = 'COMPANY_SUGGESTIONS';
export const GET_SKILLS = 'GET_SKILLS';
export const JOB_TITLE_SUGGESTIONS = 'JOB_TITLE_SUGGESTIONS';
export const SUBMIT_JOB_TITLE = 'SUBMIT_JOB_TITLE';
export const EMPTY_SUGGESTION = 'EMPTY_SUGGESTION';


// Manage content
export const RESET_lOADING = 'RESET_lOADING';
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS';
export const OPEN_ADD_SKILL = 'OPEN_ADD_SKILL';
export const CLOSE_ADD_SKILL = 'CLOSE_ADD_SKILL';
export const GET_NEXT_SKILLS = 'GET_NEXT_SKILLS';
export const ADD_SKILL = 'ADD_SKILL';
export const CHANGE_SKILL_STATUS = 'CHANGE_SKILL_STATUS';
export const RESET_SKILL_PATHS = 'RESET_SKILL_PATHS';
export const GET_SKILL_PATH = 'GET_SKILL_PATH';
export const CHANGE_SKILLPATH_STATUS = 'CHANGE_SKILLPATH_STATUS';
export const REORDER_SKILLPATH = 'REORDER_SKILLPATH';
export const ADD_SKILLPATH = 'ADD_SKILLPATH';
export const EDIT_SKILLPATH = 'EDIT_SKILLPATH';
export const GET_SKILL_PODS = 'GET_SKILL_PODS';
export const CHANGE_SKILLPOD_STATUS = 'CHANGE_SKILLPOD_STATUS';
export const REORDER_SKILLPOD = 'REORDER_SKILLPOD';
export const ADD_SKILL_POD = 'ADD_SKILL_POD';
export const GET_SUBTOPICS = 'GET_SUBTOPICS';
export const GET_SUBTOPICS_FAILED = 'GET_SUBTOPICS_FAILED';
export const ADD_SUBTOPIC = 'ADD_SUBTOPIC';
export const EDIT_SUBTOPIC = 'EDIT_SUBTOPIC';
export const RESET_RESOURCES = 'RESET_RESOURCES';
export const CHANGE_SUBTOPIC_STATUS = 'CHANGE_SUBTOPIC_STATUS';
export const REORDER_SUBTOPIC = 'REORDER_SUBTOPIC';
export const GET_SUBTOPIC_RESOURCES = 'GET_SUBTOPIC_RESOURCES';
export const CHANGE_RESOURCE_STATUS = 'CHANGE_RESOURCE_STATUS';
export const GET_PREVIEW = 'GET_PREVIEW';
export const CHANGE_SOURCE = 'CHANGE_SOURCE';
export const NULL_PREVIEW = 'NULL_PREVIEW';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const REORDER_RESOURCES = 'REORDER_RESOURCES';
export const GET_DOCUMENTS_STATE = 'GET_DOCUMENTS_STATE';
export const GET_DOCUMENTS_PERCENT = 'GET_DOCUMENTS_PERCENT';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const GET_SEARCH_SKILLS = 'GET_SEARCH_SKILLS';
export const GET_SKILL_TOPICS = 'GET_SKILL_TOPICS';
export const GET_DATA_FOR_EDIT = 'GET_DATA_FOR_EDIT';
export const RESET_PERCENTAGE = 'RESET_PERCENTAGE';
export const GET_SKILL_QUESTION = 'GET_SKILL_QUESTION';
export const GET_NEXT_SKILL_QUESTION = 'GET_NEXT_SKILL_QUESTION';
export const GET_QUESTION_LIST = 'GET_QUESTION_LIST';
export const GET_NEXT_QUESTION_LIST = 'GET_NEXT_QUESTION_LIST';
export const CHANGE_QUESTION_STATUS = 'CHANGE_QUESTION_STATUS';
export const Close_Questions = 'Close_Questions';
export const SET_TITLE = 'SET_TITLE';
export const SET_EXPLANATION = 'SET_EXPLANATION';
export const SET_CHOICE1 = 'SET_CHOICE1';
export const SET_CHOICE2 = 'SET_CHOICE2';
export const SET_CHOICE3 = 'SET_CHOICE3';
export const SET_CHOICE4 = 'SET_CHOICE4';
export const GET_SKILL_TESTS = 'GET_SKILL_TESTS';
export const GET_QUIZ_QUESTIONS = 'GET_QUIZ_QUESTIONS';
export const Change_Practice_Status = 'Change_Practice_Status';
export const Add_Question_Quiz = 'Add_Question_Quiz';
export const Delete_Question_Quiz = 'Delete_Question_Quiz';
export const Delete_All_Question_Quiz = 'Delete_All_Question_Quiz';


//Learning bytes
export const GET_CONTENTS_LIST = 'GET_CONTENTS_LIST';
export const RESET_RESOURCES_LOADING = 'RESET_RESOURCES_LOADING';
export const CHANGE_LEARNING_RESOURCE_STATUS = 'CHANGE_LEARNING_RESOURCE_STATUS';
export const GET_NEXT_CONTENTS_LIST = 'GET_NEXT_CONTENTS_LIST';
export const ADD_LEARNING_RESOURCE = 'ADD_LEARNING_RESOURCE';
export const CHANGE_LEARNING_SOURCE = 'CHANGE_LEARNING_SOURCE';
export const RESET_LEARNING_PERCENTAGE = 'RESET_LEARNING_PERCENTAGE';
export const GET_LEARNING_DOCUMENTS_STATE = 'GET_LEARNING_DOCUMENTS_STATE';
export const GET_LEARNING_DOCUMENTS_PERCENT = 'GET_LEARNING_DOCUMENTS_PERCENT';
export const UPDATE_LEARNING_RESOURCE = 'UPDATE_LEARNING_RESOURCE';

//User-report
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_NEXT_USER_LIST = 'GET_NEXT_USER_LIST';
export const RESET_LOADER = 'RESET_LOADER';
export const Companies_Login_Trend = 'Companies_Login_Trend';
export const Companies_Score_Trend = 'Companies_Score_Trend';
export const GET_SKILL_STATS = 'GET_SKILL_STATS';
export const GET_SKILL_PERCENT = 'GET_SKILL_PERCENT';
export const GET_MONTHLY_DATA = 'GET_MONTHLY_DATA';
export const GET_CONSUMPTION = 'GET_CONSUMPTION';
export const GET_ENGAGEMENT_SCORE = 'GET_ENGAGEMENT_SCORE';

//contests
export const GET_CONTESTS = 'GET_CONTESTS';
export const CONTEST_STATUS = 'CONTEST_STATUS';
export const GET_COMPANIES = 'GET_COMPANIES';


//Error
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

