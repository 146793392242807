import {
    GET_CONTENTS_LIST, RESET_RESOURCES_LOADING, CHANGE_LEARNING_RESOURCE_STATUS, GET_NEXT_CONTENTS_LIST,
    ADD_LEARNING_RESOURCE, CHANGE_LEARNING_SOURCE, RESET_LEARNING_PERCENTAGE, GET_LEARNING_DOCUMENTS_STATE,
    GET_LEARNING_DOCUMENTS_PERCENT, UPDATE_LEARNING_RESOURCE
} from '../actionType/types';

/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
let intialState = {
    skillList: [],
    isSkillLoading: true,
    isShow: false,
    isResourceLoading: true,
    articleSource: '',
    percent: 0,
    documentDataState: false,
    active: 'Video'
}

export default function LearningBytesReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_CONTENTS_LIST:
            stateCopy.resources = action.payload;
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case RESET_RESOURCES_LOADING:
            stateCopy.isResourceLoading = true;
            return stateCopy;
        case GET_NEXT_CONTENTS_LIST:
            stateCopy.resources = stateCopy.resources.concat(action.payload)
            return stateCopy;
        case CHANGE_LEARNING_RESOURCE_STATUS:
            stateCopy.resources.forEach((answer) => {
                if (answer._id == action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;
        case ADD_LEARNING_RESOURCE:
            stateCopy.resources = [...stateCopy.resources, action.payload.data];
            stateCopy.active = action.payload.type;
            stateCopy.articleSource = '';
            return stateCopy;
        case CHANGE_LEARNING_SOURCE:
            stateCopy.articleSource = action.payload;
            return stateCopy;
        case RESET_LEARNING_PERCENTAGE:
            stateCopy.percent = 0;
            return stateCopy;
        case GET_LEARNING_DOCUMENTS_PERCENT:
            stateCopy.percent = action.payload;
            return stateCopy;
        case GET_LEARNING_DOCUMENTS_STATE:
            stateCopy.documentDataState = action.payload;
            return stateCopy;
        case UPDATE_LEARNING_RESOURCE:
            stateCopy.active = action.payload.type;
            return stateCopy;
        default:
            return stateCopy;
    }
}
;
