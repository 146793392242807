import {
  RESET_lOADING, GET_ALL_SKILLS, GET_NEXT_SKILLS, OPEN_ADD_SKILL, CLOSE_ADD_SKILL, ADD_SKILL, CHANGE_SKILL_STATUS, RESET_SKILL_PATHS, GET_SKILL_PATH, CHANGE_SKILLPATH_STATUS,
  REORDER_SKILLPATH, ADD_SKILLPATH, EDIT_SKILLPATH, GET_SKILL_PODS, CHANGE_SKILLPOD_STATUS, REORDER_SKILLPOD, ADD_SKILL_POD, GET_SUBTOPICS, GET_SUBTOPICS_FAILED, ADD_SUBTOPIC, RESET_RESOURCES, CHANGE_SUBTOPIC_STATUS,
  REORDER_SUBTOPIC, GET_SUBTOPIC_RESOURCES, GET_PREVIEW, CHANGE_SOURCE, CHANGE_RESOURCE_STATUS, NULL_PREVIEW, ADD_RESOURCE,
  REORDER_RESOURCES, GET_DOCUMENTS_STATE, GET_SEARCH_SKILLS, GET_SKILL_TOPICS, GET_DOCUMENTS_PERCENT, GET_SKILL_QUESTION, GET_NEXT_SKILL_QUESTION, GET_QUESTION_LIST, GET_NEXT_QUESTION_LIST, Close_Questions, CHANGE_QUESTION_STATUS, GET_DATA_FOR_EDIT,
  RESET_PERCENTAGE, SET_TITLE, SET_EXPLANATION, SET_CHOICE1, SET_CHOICE2, SET_CHOICE3, SET_CHOICE4,
  GET_SKILL_TESTS, GET_QUIZ_QUESTIONS, Add_Question_Quiz, Delete_Question_Quiz, Delete_All_Question_Quiz, Change_Practice_Status
} from "../actionType/types";

/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
let intialState = {
  skillList: [],
  isSkillLoading: true,
  isShow: false,
  isSkillPathLoading: true,
  isSkillPodLoading: true,
  isSubTopicLoading: true,
  isResourceLoading: true,
  articleSource: "",
  percent: 0,
  documentDataState: false,
  isViewed: false,
  practiceTestList: [],
  clubQuestionList: [],
  qTitle: '',
  qExplanation: '',
  choice1: '',
  questionListForQuiz: []
};

export default function ContentReducer(state = intialState, action) {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case RESET_lOADING:
      stateCopy.isSkillLoading = true;
      stateCopy.isViewed = false;
      return stateCopy
    case GET_ALL_SKILLS:
      stateCopy.skillList = action.payload;
      stateCopy.skillListCopy = action.payload;
      stateCopy.isSkillLoading = false;
      return stateCopy;
    case GET_NEXT_SKILLS:
      stateCopy.skillList = stateCopy.skillList.concat(action.payload);
      stateCopy.skillListCopy = stateCopy.skillList.concat(action.payload);
      stateCopy.isSkillLoading = false;
      return stateCopy;
    case OPEN_ADD_SKILL:
      stateCopy.isShow = true;
      return stateCopy;
    case CLOSE_ADD_SKILL:
      stateCopy.isShow = false;
      return stateCopy;
    case ADD_SKILL:
      stateCopy.skillList = [...stateCopy.skillList, action.payload];
      stateCopy.isShow = false;
      return stateCopy;
    case CHANGE_SKILL_STATUS:
      stateCopy.skillList.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case RESET_SKILL_PATHS:
      stateCopy.skillPaths = [];
      stateCopy.isSkillPathLoading = true;
      return stateCopy;
    case GET_SKILL_PATH:
      stateCopy.skillPaths = action.payload.sort((a, b) =>
        a.index > b.index ? 1 : -1
      );
      stateCopy.isSkillPathLoading = false;
      return stateCopy;
    case CHANGE_SKILLPATH_STATUS:
      stateCopy.skillPaths.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case REORDER_SKILLPATH:
      const skillpath = Array.from(stateCopy.skillPaths);
      const [reorderedSkillPath] = skillpath.splice(
        action.payload.source.index,
        1
      );
      skillpath.splice(action.payload.destination.index, 0, reorderedSkillPath);
      skillpath.forEach((el, i) => {
        if (el.index !== i + 1) {
          el.index = i + 1;
        }
      });
      stateCopy.skillPaths = skillpath;
      return stateCopy;
    case ADD_SKILLPATH:
      stateCopy.skillPaths = [...stateCopy.skillPaths, action.payload];
      return stateCopy;
    case EDIT_SKILLPATH:
      return stateCopy;
    case GET_SKILL_PODS:
      stateCopy.skillPods = action.payload
        .map((item) => ({
          ...item,
          items:
            item.articles +
            item.videos +
            item.podcasts +
            item.courses +
            item.documents,
        }))
        .sort((a, b) => (a.index > b.index ? 1 : -1));
      stateCopy.isSkillPodLoading = false;
      return stateCopy;
    case CHANGE_SKILLPOD_STATUS:
      stateCopy.skillPods.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case REORDER_SKILLPOD:
      const skillpod = Array.from(stateCopy.skillPods);
      const [reorderedSkillPod] = skillpod.splice(
        action.payload.source.index,
        1
      );
      skillpod.splice(action.payload.destination.index, 0, reorderedSkillPod);
      skillpod.forEach((el, i) => {
        if (el.index !== i + 1) {
          el.index = i + 1;
        }
      });
      stateCopy.skillPods = skillpod;
      return stateCopy;
    case ADD_SKILL_POD:
      stateCopy.skillPods = [...stateCopy.skillPods, action.payload];
      return stateCopy;
    case GET_SUBTOPICS:
      stateCopy.subTopics = action.payload.sort((a, b) =>
        a.index > b.index ? 1 : -1
      );
      stateCopy.isSubTopicLoading = false;
      return stateCopy;
    case GET_SUBTOPICS_FAILED:
      stateCopy.isSubTopicLoading = false;
      return stateCopy;
    case ADD_SUBTOPIC:
      stateCopy.subTopics = [...stateCopy.subTopics, action.payload];
      return stateCopy;
    case RESET_RESOURCES:
      stateCopy.resourceList = [];
      stateCopy.isResourceLoading = true;
      return stateCopy;
    case CHANGE_SUBTOPIC_STATUS:
      stateCopy.subTopics.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case GET_SUBTOPIC_RESOURCES:
      stateCopy.resourceList = action.payload.sort((a, b) =>
        a.index > b.index ? 1 : -1
      );
      stateCopy.isResourceLoading = false;
      return stateCopy;

    case REORDER_SUBTOPIC:
      const subtopic = Array.from(stateCopy.subTopics);
      const [reorderedSubtopic] = subtopic.splice(
        action.payload.source.index,
        1
      );
      subtopic.splice(action.payload.destination.index, 0, reorderedSubtopic);
      subtopic.forEach((el, i) => {
        if (el.index !== i + 1) {
          el.index = i + 1;
        }
      });
      stateCopy.subTopics = subtopic;
      return stateCopy;
    case CHANGE_RESOURCE_STATUS:
      stateCopy.resourceList.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case GET_PREVIEW:
      stateCopy.previewData = action.payload;
      stateCopy.articleSource = action.payload.site_name
        ? action.payload.site_name
        : "";
      return stateCopy;
    case CHANGE_SOURCE:
      stateCopy.articleSource = action.payload;
      return stateCopy;
    case NULL_PREVIEW:
      stateCopy.previewData = action.payload;
      stateCopy.articleSource = "";
      return stateCopy;
    case ADD_RESOURCE:
      stateCopy.resourceList = [...stateCopy.resourceList, action.payload];
      stateCopy.articleSource = "";
      return stateCopy;
    case REORDER_RESOURCES:
      const resources = Array.from(stateCopy.resourceList);
      const [reorderedSubtopicResource] = resources.splice(
        action.payload.source.index,
        1
      );
      resources.splice(
        action.payload.destination.index,
        0,
        reorderedSubtopicResource
      );
      resources.forEach((el, i) => {
        if (el.index !== i + 1) {
          el.index = i + 1;
        }
      });
      stateCopy.resourceList = resources;
      return stateCopy;
    case GET_DOCUMENTS_STATE:
      stateCopy.documentDataState = action.payload;
      return stateCopy;
    case GET_DOCUMENTS_PERCENT:
      stateCopy.percent = action.payload;
      return stateCopy;
    case RESET_PERCENTAGE:
      stateCopy.percent = 0;
      stateCopy.percent = 0;
      stateCopy.qTitle = '';
      stateCopy.choice1 = '';
      stateCopy.choice2 = '';
      stateCopy.choice3 = '';
      stateCopy.choice4 = '';
      stateCopy.qExplanation = '';
      return stateCopy;
    case GET_SEARCH_SKILLS:
      stateCopy.skillSuggestion = action.payload;
      return stateCopy;
    case GET_SKILL_TOPICS:
      stateCopy.skillTopics = action.payload.map(el => [{ value: el._id, label: el.title }][0]);
      return stateCopy;
    //add questions
    case GET_SKILL_QUESTION:
      stateCopy.clubQuestionList = action.payload;
      stateCopy.isViewed = false;
      stateCopy.isSkillLoading = false;
      return stateCopy;
    case Close_Questions:
      stateCopy.isViewed = false;
      stateCopy.isSkillLoading = false;
      return stateCopy;
    case GET_NEXT_SKILL_QUESTION:
      stateCopy.clubQuestionList = stateCopy.clubQuestionList.concat(action.payload);
      stateCopy.isSkillLoading = false;
      return stateCopy;
    case GET_QUESTION_LIST:
      stateCopy.questionList = action.payload;
      stateCopy.isViewed = true;
      stateCopy.isSkillLoading = false;
      return stateCopy;
    case GET_NEXT_QUESTION_LIST:
      stateCopy.questionList = stateCopy.questionList.concat(action.payload);
      return stateCopy;
    case CHANGE_QUESTION_STATUS:
      stateCopy.questionList.forEach(answer => {
        if (answer._id === action.payload.id) {
          answer.active = !action.payload.checked;
        }
      })
      return stateCopy;
    case GET_DATA_FOR_EDIT:
      stateCopy.editData = action.payload;
      stateCopy.questionData = action.payload;
      stateCopy.qTitle = action.payload.title;
      stateCopy.qExplanation = action.payload.description;
      return stateCopy;
    case SET_TITLE:
      stateCopy.qTitle = action.payload;
      return stateCopy;
    case SET_EXPLANATION:
      stateCopy.qExplanation = action.payload;
      return stateCopy;
    case SET_CHOICE1:
      stateCopy.choice1 = action.payload;
      return stateCopy;
    case SET_CHOICE2:
      stateCopy.choice2 = action.payload;
      return stateCopy;
    case SET_CHOICE3:
      stateCopy.choice3 = action.payload;
      return stateCopy;
    case SET_CHOICE4:
      stateCopy.choice4 = action.payload;
      return stateCopy;
    //ADD PRACTICE TESTS
    case GET_SKILL_TESTS:
      stateCopy.practiceList = action.payload;
      stateCopy.isSkillLoading = false;
      stateCopy.questionList = [];
      stateCopy.quizType = 'MCQ';
      stateCopy.questionListForQuiz = [];
      return stateCopy;
    case GET_QUIZ_QUESTIONS:
      stateCopy.questionListForQuiz = action.payload.questions;
      stateCopy.quizType = action.payload.quiz_type;
      return stateCopy;
    case Add_Question_Quiz:
      const updatedQuestionList = stateCopy.questionList.map((question) => {
        if (question._id === action.payload._id) {
          return { ...question, checked: true };
        }
        return question;
      });
      let newData = {
        ...stateCopy,
        questionList: [...updatedQuestionList],
        questionListForQuiz: [...stateCopy.questionListForQuiz, action.payload],
      };
      return newData;
    case Delete_Question_Quiz:
      const dataSource = [...stateCopy.questionListForQuiz];
      const filteredQuestions = dataSource.filter(
        (allQuestions) => allQuestions._id !== action.payload
      );
      return {
        ...stateCopy,
        questionList: [
          ...stateCopy.questionList.map((question) => {
            if (question._id === action.payload) {
              return { ...question, checked: false };
            }
            return question;
          }),
        ],
        questionListForQuiz: filteredQuestions,
      };
    case Delete_All_Question_Quiz:
      stateCopy.questionListForQuiz = [];
      return stateCopy;
    case Change_Practice_Status:
      stateCopy.practiceList.forEach(answer => {
        if (answer._id === action.payload.id) {
          answer.active = !action.payload.checked;
        }
      })
      return stateCopy;
    default:
      return stateCopy;
  }
}
