import { GET_PROVIDERS, PROVIDER_STATUS } from '../actionType/types';

let intialState = {
    providers: []
}

export default function providerReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_PROVIDERS:
            stateCopy.providers = action.payload;
            return stateCopy;
        case PROVIDER_STATUS:
            stateCopy.providers.forEach(element => {
                if (element._id === action.payload.id) {
                    element.active = action.payload.active ? false : true
                }
            });
            return stateCopy;
        default:
            return stateCopy;
    }
}
