import { GET_SESSIONS, GET_NEXT_SESSIONS } from '../actionType/types';

let intialState = {
    sessions: [],
    isSessionLoading: true
}

export default function sessionReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_SESSIONS:
            stateCopy.sessions = action.payload;
            stateCopy.isSessionLoading = false;
            return stateCopy;
        case GET_NEXT_SESSIONS:
            stateCopy.sessions = stateCopy.sessions.concat(action.payload);
            stateCopy.isSessionLoading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}
