import { GET_CONTENT_LIST, GET_NEXT_CONTENT_LIST, REFRESH_CONTENT, CHANGE_CONTENT_STATUS, APPROVED } from '../actionType/types';

let intialState = {
    contentList: [],
    isContentLoading: true
}

export default function userContentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_CONTENT_LIST:
            stateCopy.contentList = action.payload;
            stateCopy.isContentLoading = false;
            return stateCopy;
        case GET_NEXT_CONTENT_LIST:
            stateCopy.contentList = stateCopy.contentList.concat(action.payload);
            stateCopy.isContentLoading = false;
            return stateCopy;
        case REFRESH_CONTENT:
            stateCopy.isContentLoading = true;
            return stateCopy;
        case APPROVED:
            stateCopy.contentList.forEach((answer) => {
                if (answer.id === action.payload._id) {
                    answer.active = action.payload.review ? true : false;
                }
            });
            return stateCopy;

        case CHANGE_CONTENT_STATUS:
            stateCopy.contentList.forEach((answer) => {
                if (answer.id === action.payload.id) {
                    answer.active = action.payload.checked ? true : false;
                }
            });
            return stateCopy;
        default:
            return stateCopy;
    }
}
