import { LOGIN_USER, LOGIN_ERROR, } from '../actionType/types';

let intialState = {
    isAuthorized: false,
    user: localStorage.getItem('superAdminProfileDetails'),
    succesMessage: false,
}

export default function loginReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case LOGIN_USER:
            stateCopy.isAuthorized = true;
            stateCopy.user = action.payload;
            localStorage.setItem('superAdminToken', action.payload.token);
            localStorage.setItem('superAdminEmail', action.payload.email);
            localStorage.setItem('superAdminName', action.payload.name);
            localStorage.setItem('superAdminProfileDetails', JSON.stringify(action.payload));
            window.location.reload();
            return stateCopy;
        case LOGIN_ERROR:
            stateCopy.succesMessage = true;
            stateCopy.succesMessageText = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}
