import { GET_TEST_LIST, GET_NEXT_TEST_LIST, GET_V2_TEST_LIST, GET_NEXT_V2_TEST_LIST, REFRESH_TEST, GET_REPORT, SCHEDULE_TEST, SUBMIT_RESULT } from '../actionType/types';

let intialState = {
    testList: [],
    isTestLoading: true
}

export default function testReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_TEST_LIST:
            stateCopy.testList = action.payload;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_NEXT_TEST_LIST:
            stateCopy.testList = stateCopy.testList.concat(action.payload);
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_V2_TEST_LIST:
            stateCopy.v2TestList = action.payload;
            stateCopy.v2TestListCopy = action.payload;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_NEXT_V2_TEST_LIST:
            stateCopy.v2TestList = stateCopy.v2TestList.concat(action.payload);
            stateCopy.isTestLoading = false;
            return stateCopy;
        case REFRESH_TEST:
            stateCopy.isTestLoading = true;
            return stateCopy;
        case GET_REPORT:
            stateCopy.reportData = action.payload;
            return stateCopy;
        case SCHEDULE_TEST:
            console.log(action.payload)
            const answersns = stateCopy.testList.map(answer => {
                if (answer._id === action.payload._id) return {
                    _id: answer._id,
                    certificate: answer.certificate,
                    certificate_id: answer.certificate_id,
                    report: answer.report,
                    company_id: answer.company_id,
                    company: answer.company,
                    score: answer.score,
                    email: answer.email,
                    name: answer.name,
                    test: answer.test,
                    date: answer.date,
                    time: answer.time,
                    description: answer.description,
                    url: action.payload.url,
                    skill: answer.skill,
                    claim: answer.claim,
                    otp: action.payload.otp,
                    provider_exam_id: answer.provider_exam_id,
                    status: 'scheduled'
                }
                return answer
            })
            stateCopy.testList = answersns;
            return stateCopy;
        case SUBMIT_RESULT:
            console.log(action.payload)
            const answers = stateCopy.testList.map(answer => {
                if (answer._id === action.payload.id) return {
                    _id: answer._id,
                    certificate: action.payload.data.certificate,
                    certificate_id: action.payload.data.certificate_id,
                    report: action.payload.data.report,
                    score: action.payload.data.score,
                    company: answer.company,
                    company_id: answer.company_id,
                    email: answer.email,
                    name: answer.name,
                    test: answer.test,
                    date: answer.date,
                    time: answer.time,
                    description: answer.description,
                    url: answer.url,
                    skill: answer.skill,
                    claim: answer.claim,
                    otp: answer.otp,
                    provider_exam_id: answer.provider_exam_id,
                    status: action.payload.status
                }
                return answer
            })
            stateCopy.testList = answers;
            return stateCopy;
        default:
            return stateCopy;
    }
}
