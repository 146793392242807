import { COMPANY_SUGGESTIONS, GET_SKILLS, JOB_TITLE_SUGGESTIONS, SUBMIT_JOB_TITLE, EMPTY_SUGGESTION } from '../actionType/types';

let intialState = {
    nonAddedSkillLoading: true
}

export default function jobTitleReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case COMPANY_SUGGESTIONS:
            stateCopy.companySuggestion = action.payload;
            return stateCopy;
        case EMPTY_SUGGESTION:
            stateCopy.companySuggestion = [];
            return stateCopy;
        case GET_SKILLS:
            stateCopy.alreadyAddedSkills = action.payload;
            stateCopy.nonAddedSkillLoading = false;
            stateCopy.companySuggestion = [];
            return stateCopy;
        case JOB_TITLE_SUGGESTIONS:
            stateCopy.jobSuggestions = action.payload;
            return stateCopy;
        case SUBMIT_JOB_TITLE:
            stateCopy.alreadyAddedSkills = stateCopy.alreadyAddedSkills.concat(action.payload);
            return stateCopy;
        default:
            return stateCopy;
    }
}
