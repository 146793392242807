import {
    GET_USER_LIST, GET_NEXT_USER_LIST, RESET_LOADER, Companies_Login_Trend, Companies_Score_Trend, GET_SKILL_STATS, GET_SKILL_PERCENT,
    GET_MONTHLY_DATA, GET_CONSUMPTION, GET_ENGAGEMENT_SCORE
} from '../actionType/types';

let intialState = {
    isLoading: true,
    userList: [],
    graphData: {}
}

export default function reportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_USER_LIST:
            stateCopy.userList = action.payload.data;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_USER_LIST:
            stateCopy.userList = stateCopy.userList.concat(action.payload.data);
            stateCopy.isLoading = false;
            return stateCopy;
        case RESET_LOADER:
            stateCopy.isLoading = true;
            return stateCopy;
        case Companies_Login_Trend:
            stateCopy.dates = action.payload.dates;
            stateCopy.logins = action.payload.num_of_logins;
            return stateCopy;
        case Companies_Score_Trend:
            stateCopy.dates = action.payload.dates;
            stateCopy.eng_score = action.payload.num_of_engagementscore;
            return stateCopy;
        case GET_SKILL_STATS:
            stateCopy.skillStats = action.payload.stats;
            const data = action.payload.data.map(item => ({
                ...item,
                total: Object.values(item.resource_count).reduce((a, b) => a + b)
            }));
            const acquired = data.filter(el => el.status === 'a');
            const less25 = data.filter(el => el.status === 'n' && el.completion <= 25);
            const more50 = data.filter(el => el.status === 'n' && (el.completion >= 25 && el.completion <= 50));
            const more50less75 = data.filter(el => el.status === 'n' && (el.completion >= 50 && el.completion <= 75));
            const more75 = data.filter(el => el.status === 'n' && el.completion >= 75);
            stateCopy.graphData = { acquired, less25, more50, more50less75, more75 }
            return stateCopy;
        case GET_SKILL_PERCENT:
            stateCopy.skillPercent = action.payload;
            return stateCopy;
        case GET_ENGAGEMENT_SCORE:
            stateCopy.score = action.payload;
            stateCopy.scores = action.payload.scores.map((el, i) => [{ y: el, color: el === Math.max(...stateCopy.score.scores) ? '#5fbb97' : '#BFB8F2' }][0]);
            return stateCopy;
        case GET_CONSUMPTION:
            stateCopy.consumption = action.payload.map(el => ({ ...el, consume_percent: [] }));
            const consume = action.payload.map(item => ({
                ...item, total_consumed: item.consumed?.articles + item.consumed?.videos + item.consumed?.podcasts + item.consumed?.documents,
                total: Object.values(item.resource_count).reduce((a, b) => a + b)
            }));
            stateCopy.consumption = consume.map(item => ({
                ...item, consume_percent:
                    [{ name: 'Video', y: Math.round((item.consumed?.videos / item.total_consumed) * 100) === 0 ? null : Math.round((item.consumed?.videos / item.total_consumed) * 100), color: '#5FBB97' },
                    { name: 'Article', y: Math.round((item.consumed?.articles / item.total_consumed) * 100) === 0 ? null : Math.round((item.consumed?.articles / item.total_consumed) * 100), color: '#E8325D' },
                    { name: 'Document', y: Math.round((item.consumed?.documents / item.total_consumed) * 100) === 0 ? null : Math.round((item.consumed?.documents / item.total_consumed) * 100), color: '#BFB8F2' },
                    { name: 'Podcast', y: Math.round((item.consumed?.podcasts / item.total_consumed) * 100) === 0 ? null : Math.round((item.consumed?.podcasts / item.total_consumed) * 100), color: '#E7C15F' },
                    { name: 'Course', y: Math.round((item.consumed?.courses / item.total_consumed) * 100) === 0 ? null : Math.round((item.consumed?.courses / item.total_consumed) * 100), color: '#52A9F9' },
                    ]
            }));
            var totalChild = stateCopy.consumption.map(el => el.consumed).reduce(function (previousValue, currentValue) {
                return {
                    articles: previousValue.articles + currentValue.articles,
                    videos: previousValue.videos + currentValue.videos,
                    podcasts: previousValue.podcasts + currentValue.podcasts,
                    documents: previousValue.documents + currentValue.documents,
                    courses: previousValue.courses + currentValue.courses,
                }
            });
            stateCopy.consumptionCount = objectToArray(totalChild);
            return stateCopy;
        case GET_MONTHLY_DATA:
            stateCopy.months = action.payload.months;
            stateCopy.consumed = action.payload.data;
            return stateCopy;
        default:
            return stateCopy;
    }
}

const objectToArray = (object) => {
    let array = [];
    for (let key in object) {
        array.push({
            name: key === 'videos' ? 'Video' : key === 'articles' ? 'Article' : key === 'podcasts' ? 'Podcast' : key === 'courses' ? 'Course' : key === 'documents' ? 'Document' : null
            , y: object[key] === 'NaN' ? null : object[key], color: key === 'videos' ? "#5FBB97" : key === 'articles' ? '#E8325D' : key === 'courses' ? '#52A9F9' : key === 'podcasts' ? '#E7C15F' : key === 'documents' ? '#BFB8F2' : null
        });
    }
    return array;
};
