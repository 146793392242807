import { SEARCHED_CONTENT } from '../actionType/types';

let intialState = {

}

export default function updateContentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case SEARCHED_CONTENT:
            console.log(action.payload)
            stateCopy.contentDetails = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}
