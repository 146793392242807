import { combineReducers } from "redux";
import companyReducer from "./companyReducer";
import errorReducer from "./errorReducer";
import jobTitleReducer from "./jobTitleReducer";
import loginReducer from "./loginReducer";
import rewardReducer from "./rewardReducer";
import testReducer from "./testReducer";
import updateContentReducer from "./updateContentReducer";
import userContentReducer from "./userContentReducer";
import ContentReducer from './manageContentReducer';
import LearningBytesReducer from "./manageLearningreducer";
import sessionReducer from "./sessionreducer";
import reportReducer from "./reportReducer";
import contestReducer from "./contestReducer";
import providerReducer from "./providerReducer";



export const rootReducer = combineReducers({
    login: loginReducer,
    error: errorReducer,
    company: companyReducer,
    reward: rewardReducer,
    userContent: userContentReducer,
    updateContent: updateContentReducer,
    test: testReducer,
    jobtitle: jobTitleReducer,
    content: ContentReducer,
    learning: LearningBytesReducer,
    mentorSession: sessionReducer,
    report: reportReducer,
    contest: contestReducer,
    provider: providerReducer
});