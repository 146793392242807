import {
    GET_COMPANY_LIST, GET_PLANS, GET_ENTERPRISE_PLANS, GET_COUNTRY, GET_MANAGER, TOGGLE_STATUS, REDIRECT_LOGIN
} from '../actionType/types';

let intialState = {
    isDataLoading: true
}

export default function companyReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_COMPANY_LIST:
            stateCopy.companyPerPage = action.payload.perPage;
            stateCopy.companyTotalPage = action.payload.total;
            stateCopy.companyList = action.payload.data;
            stateCopy.companyListCopy = action.payload.data;
            return stateCopy;
        case GET_PLANS:
            stateCopy.plans = action.payload;
            return stateCopy;
        case GET_ENTERPRISE_PLANS:
            stateCopy.enterprisePlans = action.payload.filter(el => el.active);
            return stateCopy;
        case GET_COUNTRY:
            stateCopy.countries = action.payload;
            return stateCopy;
        case GET_MANAGER:
            stateCopy.managers = action.payload;
            return stateCopy;
        case TOGGLE_STATUS:
            const answersns = stateCopy.companyList.map(answer => {
                if (answer.id === action.payload.checkid.id) return {
                    id: answer.id, date: answer.date, name: answer.name, last_7day: answer.last_7day, users: answer.users, yesterday: answer.yesterday, active: action.payload.checked, plan_name: answer.plan_name, plan_id: answer.plan_id, restriction: answer.restriction, admin_email: answer.admin_email, admin_name: answer.admin_name, country_id: answer.country_id, country_name: answer.country_name,
                    manager_name: answer.manager_name, manager_email: answer.manager_email, manager_id: answer.manager_id, start: answer.start, end: answer.end, test_credit: answer.test_credit, mobile: answer.mobile
                }
                return answer
            })
            stateCopy.companyList = answersns;
            return stateCopy;
        case REDIRECT_LOGIN:
            window.open(action.payload, "_blank")
            return stateCopy;
        default:
            return stateCopy;
    }
}
